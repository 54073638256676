<template>
    <CommonLayoutsVue>
        <template v-slot:child>
            <b-row style="max-width:375px" class="centered-container">
                <b-row class="mt-4">
                    <b-col class="header-container mb-3">
                        <h2 class="centered-header">Please enter OTP</h2>
                        <span class="span-item">
                            Please check your email for the OTP
                        </span>
                    </b-col>
                </b-row>
                <b-row class="centered-content ">
                    <div class="otp-input">
                        <input v-for="(digit, index) in otpDigits" :key="index" :id="index" v-model="otpDigits[index]"
                            type="tel" pattern="^\d{1,9}$" ref="otpInput" @keyup="handleKeyDown($event, index)"
                            class="otp-box" maxlength="1" :tabindex="index" />
                    </div>

                    <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                        <span style="display:flex;align-items:center;color:#9C9CA4;">Back</span>
                        <b-button variant="primary" size="sm">Verify</b-button>
                    </div>
                    <div class="col-12 text-center my-4">
                        <span>Haven’t received the OTP?&nbsp;</span>
                        <router-link to="candidate-register">Resend</router-link>
                    </div>
                </b-row>
            </b-row>
        </template>
    </CommonLayoutsVue>
</template>

<script>
import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'

export default {
    name: "OTPScreen",
    components: { CommonLayoutsVue },
    data() {
        return {
            otpDigits: ['', '', '', ''],
        }
    },
    methods: {
        handleKeyDown(event, index) {
            console.log('Key Pressed:', event);
            // console.log('Current Index:', index);
            if (event.key === 'Backspace' && index > 0 && !this.otpDigits[index]) {
                // If Backspace is pressed and the current box is empty
                // Clear the previous box
                this.otpDigits[index - 1] = '';
                // Focus on the previous box
                this.$refs.otpInput[index - 1].focus();
            } else if (event.key.match(/[0-9]/) && index < 3) {
                // } else if (event.target.tabIndex.toString().match(/[0-9]/) && index < 3 && this.otpDigits[index] !== '') {
                this.$refs.otpInput[index + 1].focus();
            }
        },
    },

}
</script>

<style>
.otp-input {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.otp-box {
    display: flex;
    width: 61.972px;
    height: 61.972px;
    gap: 10px;
    flex-shrink: 0;
    text-align: center;
    background: var(--neutral-soft-grey);
    border: none;
    border-radius: 10px;
}
</style>