<template>
    <div>
        <!-- Header @start -->
        <Header />
        <!-- Header @end -->
        <b-container fluid :style="layoutContainerStyle" class="common-layout-container  mt-5">
            <b-row class="cover-container">


                <!-- <img v-if="isMobileOrTablet" class="cover-img"
                    src="@/assets/zaajira-candidate/assets/bg-images/fullcover.png" alt="" srcset=""> -->
                <img v-if="!isMobileOrTablet" class="cover-img" src="@/assets/zaajira-candidate/assets/bg-images/Bitmap.png"
                    alt="" srcset="">

                <!-- <b-row> -->
                <div :style="layoutContentStyle" :class="layoutContentClass" class="layout-content">
                    <div v-if="isLogo" class="company-logo">
                        <img class="logo-img" src="@/assets/zaajira-candidate/assets/logo/zaajira-logo.svg" alt=""
                            srcset="">
                    </div>
                    <slot name="child"></slot>
                </div>
                <!-- </b-row> -->
            </b-row>
            <!-- <Footer /> -->
        </b-container>

    </div>
</template>

<script>
import Header from '../Partials/Header.vue'
export default {
    name: "CommonLayouts",
    components: { Header },
    props: {
        isLogo: {
            type: Boolean,
            default: true,
        },
        layoutContainerStyle: {
            type: String,
            default: '',
        },
        layoutContentStyle: {
            type: String,
            default: '',
        },
        layoutContentClass: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isMobileOrTablet: window.innerWidth <= 786
        };
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.handleResize);
    },
    methods: {
        handleResize() {
            this.isMobileOrTablet = window.innerWidth <= 768;
        },
    },
}
</script>

<style>
.common-layout-container {
    width: 100%;
    /* height: calc(100vh + -15vh); */
}

.cover-container {
    position: relative;
}

.company-logo {}

.company-logo .logo-img {
    width: 12rem;
}

.cover-img {
    position: fixed;
    width: 100%;
    height: 50%;
    -o-object-position: bottom;
    object-position: bottom;
    max-height: none;
    -o-object-fit: cover;
    object-fit: cover;
    z-index: -1;
}

.layout-content {
    /* position: absolute; */
    margin-top: 5rem;
    display: flex;
    width: 100%;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

@media screen and (max-width:992px) {
    .common-layout-container {
        width: 100%;
        /* height: calc(100vh + -0vh); */
    }
}
</style>